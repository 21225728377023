.open-hours-main {
    max-width: 25%;
    /*background: rgba(0, 0, 0, 0.3);*/
}

.open-hours-title {
    font-size: 1.5rem;
}

.open-hours-entry {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1.3em;
}

.open-hours-main .row div:nth-child(1) {
    text-align: left;
}

.open-hours-main .row div:nth-child(2) {
    text-align: center;
}

.open-hours-main .row div:nth-child(3) {
    text-align: right;
}


.main-section .contact-info {
    opacity: 1;
    transition: opacity 500ms;
    background: rgba(0, 0, 0, 0.7) !important;
}

.main-section .carousel-caption .div {
    background: rgba(0, 0, 0, 0.7) !important;
}

.btn {
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel-caption {
    top: 10rem
}

.caption-text {
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.1);*/
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 55%, rgba(69, 69, 69, 0.0) 100%);
    z-index: 0;
}

.full-width-button button {
    /*width: 100%;*/
    padding: 1rem;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.4);
}

.full-width-button .dropdown-menu {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
}

.w-100 button {
    width: 100%;
}


#bookTableModal .modal-dialog {
    width: 420px;
}

#bookTableModal .modal-dialog {
    height: 700px;
}

#bookTableModal iframe {
    min-height: 650px;
}


@media screen and (max-width: 480px) {

    .open-hours-main {
        max-width: 100%;
    }

    .open-hours-entry {
        /*margin-bottom: 1rem;*/
        /*margin-top: 1rem;*/
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
        height: 25px;
    }

    .open-hours-title {
        font-size: 1rem;
    }

    .carousel-caption {
        top: 5rem;
    }

    .full-width-button button {
        /*width: 100%;*/
        height: 45px;
        font-size: 0.8rem;
        padding: 0;
        border-radius: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    .full-width-button .dropdown-menu {
        width: 100%;
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .w-100 button {
        width: 100%;
    }
}