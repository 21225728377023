@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
/*@import 'node_modules/bootstrap-icons/font/bootstrap-icons.css';*/

* {
    font-family: 'Mulish', sans-serif;
}

html, body, #root {
    height: 100%;
}

hr.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    padding: 0;
    margin: 1rem;
}

@media screen and (max-width: 480px) {
    hr.separator {
        margin: 0.3rem;
    }
}

.nav-link {
    color: #000 !important;
}


/* Height for devices larger than 576px */
@media (max-height: 600px) {
    #overlayInfoBox {
        display: none !important;
    }

    #contact {
        display: block !important;
    }
}
