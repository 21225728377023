.navbar {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    caret-color: transparent !important;
    background: rgba(0, 0, 0, 0.1) !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .nav-link {
    color: white !important;
}

.nav-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.nav-link {
    font-size: 1.25rem !important;
}


/*.navbar .navbar-collapse {*/
/*    text-align: center;*/
/*}*/

/*.navbar .book-table-button {*/
/*    display: none;*/
/*}*/

.navbar-brand img {
    height: 40px;
}

/*@media only screen and (min-width: 992px) {*/
/*    .navbar .book-table-button {*/
/*        display: block;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 991px) {*/
/*    .navbar .eli-text {*/
/*        font-size: 1.2rem;*/
/*    }*/
/*}*/

/*.book-table-modal .modal-body {*/
/*    background-color: transparent;*/
/*}*/

/*.book-table-modal iframe {*/
/*    min-height: 600px;*/
/*}*/

/*.menu-pdf-download {*/
/*    text-decoration: none;*/
/*}*/


@media screen and (max-width: 480px) {
    .navbar {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: rgba(0, 0, 0, 0.4) !important;
    }

    .navbar-collapse {
        /*background: rgba(0, 0, 0, 0.5) !important;*/

    }

    .navbar-brand img {
        height: 30px;
    }
}