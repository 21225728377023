@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
/*@import 'node_modules/bootstrap-icons/font/bootstrap-icons.css';*/

* {
    font-family: 'Mulish', sans-serif;
}

html, body, #root {
    height: 100%;
}

hr.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    padding: 0;
    margin: 1rem;
}

@media screen and (max-width: 480px) {
    hr.separator {
        margin: 0.3rem;
    }
}

.nav-link {
    color: #000 !important;
}


/* Height for devices larger than 576px */
@media (max-height: 600px) {
    #overlayInfoBox {
        display: none !important;
    }

    #contact {
        display: block !important;
    }
}


.navbar {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    caret-color: transparent !important;
    background: rgba(0, 0, 0, 0.1) !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .nav-link {
    color: white !important;
}

.nav-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.nav-link {
    font-size: 1.25rem !important;
}


/*.navbar .navbar-collapse {*/
/*    text-align: center;*/
/*}*/

/*.navbar .book-table-button {*/
/*    display: none;*/
/*}*/

.navbar-brand img {
    height: 40px;
}

/*@media only screen and (min-width: 992px) {*/
/*    .navbar .book-table-button {*/
/*        display: block;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 991px) {*/
/*    .navbar .eli-text {*/
/*        font-size: 1.2rem;*/
/*    }*/
/*}*/

/*.book-table-modal .modal-body {*/
/*    background-color: transparent;*/
/*}*/

/*.book-table-modal iframe {*/
/*    min-height: 600px;*/
/*}*/

/*.menu-pdf-download {*/
/*    text-decoration: none;*/
/*}*/


@media screen and (max-width: 480px) {
    .navbar {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: rgba(0, 0, 0, 0.4) !important;
    }

    .navbar-collapse {
        /*background: rgba(0, 0, 0, 0.5) !important;*/

    }

    .navbar-brand img {
        height: 30px;
    }
}
.open-hours-main {
    max-width: 25%;
    /*background: rgba(0, 0, 0, 0.3);*/
}

.open-hours-title {
    font-size: 1.5rem;
}

.open-hours-entry {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1.3em;
}

.open-hours-main .row div:nth-child(1) {
    text-align: left;
}

.open-hours-main .row div:nth-child(2) {
    text-align: center;
}

.open-hours-main .row div:nth-child(3) {
    text-align: right;
}


.main-section .contact-info {
    opacity: 1;
    transition: opacity 500ms;
    background: rgba(0, 0, 0, 0.7) !important;
}

.main-section .carousel-caption .div {
    background: rgba(0, 0, 0, 0.7) !important;
}

.btn {
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel-caption {
    top: 10rem
}

.caption-text {
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.1);*/
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 55%, rgba(69, 69, 69, 0.0) 100%);
    z-index: 0;
}

.full-width-button button {
    /*width: 100%;*/
    padding: 1rem;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.4);
}

.full-width-button .dropdown-menu {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
}

.w-100 button {
    width: 100%;
}


#bookTableModal .modal-dialog {
    width: 420px;
}

#bookTableModal .modal-dialog {
    height: 700px;
}

#bookTableModal iframe {
    min-height: 650px;
}


@media screen and (max-width: 480px) {

    .open-hours-main {
        max-width: 100%;
    }

    .open-hours-entry {
        /*margin-bottom: 1rem;*/
        /*margin-top: 1rem;*/
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
        height: 25px;
    }

    .open-hours-title {
        font-size: 1rem;
    }

    .carousel-caption {
        top: 5rem;
    }

    .full-width-button button {
        /*width: 100%;*/
        height: 45px;
        font-size: 0.8rem;
        padding: 0;
        border-radius: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    .full-width-button .dropdown-menu {
        width: 100%;
        padding: 0.2rem;
        font-size: 0.7rem;
    }

    .w-100 button {
        width: 100%;
    }
}
.contact svg {
    margin-right: 0.5rem;
}

.contact .social svg {
    font-size: 2rem;
}

.contact .social svg:first-child {
    margin-left: 0.7rem;
}
.contact a {
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none;
}

.contact a:hover {
    color: rgba(255, 255, 255, 0.9) !important;
}

.contact .open-hours {
    white-space: pre-line;
}


.about-content {
    font-size: 1.25rem;
}

@media screen and (max-width: 480px) {

    .about-content {
        font-size: 1rem;
    }

}
