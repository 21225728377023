.about-content {
    font-size: 1.25rem;
}

@media screen and (max-width: 480px) {

    .about-content {
        font-size: 1rem;
    }

}